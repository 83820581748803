





























































































































































import Vue from 'vue'
import { Component, Watch } from 'vue-property-decorator'
import { appendSitePrefix } from '@/utils/routeUtils'

@Component
export default class MekonomenOrdersHealthCheck extends Vue {
  loading = false
  pagination = {
    descending: true,
    page: 1,
    itemsPerPage: 50,
  }

  totalItems = null
  modalData = null
  filterOrderId = null
  minAge = 1
  showOkOrders = false
  hideCancelledOrders = false
  timer = null
  supplierId = null
  supplierModelSearchItems = []
  excludeSupplier = false
  newTicketModel = {
    comment: '',
    id: null,
  }

  ticketFilter = 'ticket_ignored'
  items = []
  additionalDataModel = false
  newTicketModal = false

  get headers() {
    return [
      { text: this.$t('Order no'), value: '_id', align: 'left' },
      { text: this.$t('Age (days)'), value: 'age_in_days', align: 'center' },
      { text: this.$t('Customer'), value: 'cust_name', align: 'center' },
      { text: this.$t('Suppliers'), value: 'suppliers', align: 'center' },
      { text: this.$t('Ordered'), value: 'is_supplier_order', align: 'center' },
      { text: this.$t('Shipped'), value: 'is_tracking_number_ok', align: 'center' },
      { text: this.$t('Invoice in'), value: 'invoice_received_status', align: 'center' },
      { text: this.$t('Invoice out'), value: 'invoice_sent', align: 'center' },
      { text: this.$t('Force OK'), value: 'force_ok', align: 'center' },
      { text: this.$t('Ticket'), value: 'ticket_id', align: 'center' },
    ]
  }

  created() {
    this.$axios.get('/v3/order/health/get_suppliers').then((response) => {
      this.supplierModelSearchItems = response.data.data.suppliers
    })
  }

  mounted() {
    const queryString = this.$route.query

    this.hideCancelledOrders = queryString.hideCancelledOrders === '1'
    this.excludeSupplier = queryString.excludeSupplier === '1'
    this.showOkOrders = queryString.showOkOrders === '1'
    this.minAge = parseInt(queryString.minAge as string) || 1

    if (queryString.supplierId) {
      this.supplierId = parseInt(queryString.supplierId as string)
    }

    if (queryString.filterOrderId) {
      this.filterOrderId = queryString.filterOrderId
    }

    if (queryString.ticketFilter) {
      this.ticketFilter = queryString.ticketFilter as string
    }

    this.fetchItems()
  }

  fetchItems() {
    if (this.timer) {
      clearTimeout(this.timer)
      this.timer = null
    }
    this.timer = setTimeout(() => {
      this.loading = true
      this.$vuetify.goTo(0, { easing: 'easeInOutQuad', duration: 150 })
      let url = `/v3/order/health?page=${this.pagination.page}`

      // filter/get item by ID
      if (this.filterOrderId) {
        url += `&order_id=${this.filterOrderId}`
      }

      // filter/get items with non canceled orders
      if (this.hideCancelledOrders) {
        url += '&hide_cancelled_orders=1'
      }

      // filter/get items with minimal age
      if (this.minAge) {
        url += `&min_age=${this.minAge}`
      }

      // filter/get by supplier
      if (this.supplierId) {
        url += `&supplier_id=${this.supplierId}`

        const excludeSupplier = this.excludeSupplier ? '1' : '0'
        url += `&exclude_supplier=${excludeSupplier}`
      }

      url += `&ticket=${this.ticketFilter}`

      this.$axios.get(url).then((response) => {
        this.totalItems = response.data.meta.total_items
        this.items = response.data.data ? response.data.data.map(this.parseItems) : []
        this.loading = false
      })
    }, 1000)
  }

  parseItems(item) {
    item.suppliers = item.supplier_name_list

    // if we have invoice id, it means invoice is sent
    item.invoice_sent = !!item.invoice_id

    item.is_tracking_number_ok = !!item.order_products.length
    item.invoice_received_status = !!item.order_products.length

    // ticket closed
    item.is_ticket_closed = [5, 6].indexOf(item.ticket_status) >= 0

    // Have we received parcel numbers from the supplier(s)?
    let parcelNumbers = []

    item.order_products.forEach((op) => {
      item.is_tracking_number_ok = item.is_tracking_number_ok && op.shipment_data.length
      item.invoice_received_status = item.invoice_received_status && op.received_document_number

      op.shipment_data.forEach((sd) => {
        parcelNumbers.push(`<a target="_blank" href="${sd.tracking_url}">${sd.tracking_number}</a>`)
      })

      parcelNumbers = parcelNumbers.filter(function (item, pos) {
        return parcelNumbers.indexOf(item) === pos
      })

      op.parcel_numbers = parcelNumbers.join(', ')
    })

    item.url = appendSitePrefix(`/${item.id}/order2/main`)

    item.isRowOk = this.isRowOk(item)
    item.showRow = this.showRow(item)

    return item
  }

  showRow(item) {
    if (item.isRowOk && this.showOkOrders === false) {
      return false
    }

    return true
  }

  isRowOk(item) {
    if (item.forced_ok_status === 0) {
      return true
    }

    if (!item.is_tracking_number_ok) {
      return false
    }

    if (!item.invoice_received_status) {
      return false
    }

    if (!item.invoice_sent) {
      return false
    }

    return true
  }

  createTicket() {
    this.$axios
      .post('/v3/order/health/ticket', this.newTicketModel)
      .then((response) => response.data)
      .then((data) => {
        const item = this.items.find(({ id }) => id === this.newTicketModel.id)

        item.ticket_id = data.data.ticket_id

        this.newTicketModel.id = null
        this.newTicketModel.comment = ''

        // close modal
        this.newTicketModal = false
      })
  }

  openNewTicketModal(data) {
    this.newTicketModel.id = data.id
    this.newTicketModal = true
  }

  openDetailsModal(data) {
    this.modalData = data
    this.additionalDataModel = true
  }

  @Watch('filterOrderId')
  @Watch('minAge')
  @Watch('hideCancelledOrders')
  @Watch('supplierId')
  @Watch('excludeSupplier')
  @Watch('ticketFilter')
  @Watch('pagination', { deep: true })
  goToRoute() {
    this.$router.push({
      name: 'Orders/Mekonomen/HealthCheck',
      query: {
        supplierId: this.supplierId,
        excludeSupplier: this.excludeSupplier ? '1' : '0',
        filterOrderId: this.filterOrderId,
        ticketFilter: this.ticketFilter,
        minAge: this.minAge.toString(),
        hideCancelledOrders: this.hideCancelledOrders ? '1' : '0',
        showOkOrders: this.showOkOrders ? '1' : '0',
      },
    })

    this.fetchItems()
  }

  @Watch('showOkOrders')
  goToRouteFrontend() {
    this.$router.push({
      name: 'Orders/Mekonomen/HealthCheck',
      query: {
        supplierId: this.supplierId,
        excludeSupplier: this.excludeSupplier ? '1' : '0',
        filterOrderId: this.filterOrderId,
        ticketFilter: this.ticketFilter,
        minAge: this.minAge.toString(),
        hideCancelledOrders: this.hideCancelledOrders ? '1' : '0',
        showOkOrders: this.showOkOrders ? '1' : '0',
      },
    })

    this.items.map(this.parseItems)
  }

  forceOkStatus(item, status) {
    item.forced_ok_status = status ? 1 : 0

    const url = '/v3/order/health/force_status'
    this.$axios
      .post(url, {
        status: status,
        order_id: item.id,
      })
      .then((response) => {})
  }
}
